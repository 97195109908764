import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import BASE_URLS from "../../baseUrl";

export const PaymentRedirect = () => {
  const location = useLocation();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const payUrl = BASE_URLS.payUrl;
  const navigate = useNavigate();
  useEffect(() => {
    // Function to parse query parameters from the URL
    const getQueryParam = (name) => {
      const params = new URLSearchParams(location.search);
      return params.get(name);
    };

    // Example: Get the value of the 'date' and 'tap_id' parameters
    const orderId = getQueryParam("order_id");
    const orderNo = getQueryParam("order_no");
    const tapId = getQueryParam("tap_id");

    const fetchData = async () => {

      try {
        const responsePromise = fetch(
          payUrl + `webadmin/api/insert_update_order_details.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderId, orderNo }),
          }
        );
        /*
            const mailPromise = fetch(
              payUrl + `webadmin/send_mail_phpmailer.php`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ orderId }),
              }
            );
        */
        // Promise.all([responsePromise, mailPromise])
        Promise.all([responsePromise])
          .then(async ([response]) => {
            if (!response.ok) {
              throw new Error("Error fetching order details");
            }

            const data = await response.json();
                                
            if (data.payment_status == "succeeded") {
              const orderDetails = data;
              navigate(`${webUrl}` + "booking-success", {
                state: {
                  orderDetails,
                },
              });
            } else {
              navigate(`${webUrl}` + "booking-failure");
            }
          })
          .catch((error) => {
            navigate(`${webUrl}` + "booking-failure");
            console.error("Error in fetchData:", error);
          });

      } catch (error) {
        navigate(`${webUrl}` + "booking-failure");
        console.error("Error in fetchData:", error);
      }


    };
    fetchData();
    // You can now use these values in your component state or perform other actions
  }, [location.search]);

};
